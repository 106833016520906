import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { aboutCompany, services } from "assets/data/consts";
import extensionCord from "assets/image/servicesImage/extension-cord.png";
import shield from "assets/image/servicesImage/shield.png";
import support from "assets/image/servicesImage/support.png";
import support1 from "assets/image/servicesImage/support1.png";
import { useOnScreen } from "hooks/useOnScreen";
import { useRef } from "react";
import { Container, Flex, HeaderText, Section, Text } from "styles";
import { IconImage, InfoWrapper } from "./styles";

const Services = () => {
	const servicesRef = useRef(null);
	const { isVisible } = useOnScreen(servicesRef);

	return (
		<div id='service_section' ref={servicesRef}>
			<Section height='noFullHeight'>
				<Container>
					<HeaderText as='h1' size='h2' color='black'>
						Oferta usług <span style={{ color: "#008000" }}>elektrycznych</span>
					</HeaderText>
					<Text color='black'>
						Zajmujemy się kompleksowym świadczeniem usług związanych z montażem i konserwacją instalacji oraz urządzeń
						elektrycznych i teletechnicznych, takimi jak:
					</Text>

					<ul style={{ margin: "40px 20px 0" }}>
						{services.map((service, index) => (
							<Flex justify_content='start' alignitems='center' gap='7px' key={index}>
								<FontAwesomeIcon icon='fa-solid fa-check' style={{ color: "#008000" }} size='1x' />
								<Text color='gray'>{service.name}</Text>
							</Flex>
						))}
					</ul>
				</Container>
			</Section>
			<Section height='noFullHeight' style={{ backgroundColor: "#f5fff0" }}>
				<Container style={{ paddingTop: "0", paddingBottom: "0" }}>
					<Flex gap='10px' alignitems='stretch' style={{ flexWrap: "wrap" }}>
						{aboutCompany.map((info, index) => {
							let imgSrc;
							switch (info.id) {
								case 3:
									imgSrc = <IconImage src={shield} alt={info.id}></IconImage>;
									break;
								case 4:
									imgSrc = <IconImage src={support} alt={info.id}></IconImage>;
									break;
								case 5:
									imgSrc = <IconImage src={extensionCord} alt={info.id}></IconImage>;
									break;
								default:
									imgSrc = <IconImage src={support1} alt={info.id}></IconImage>;
							}

							return (
								<InfoWrapper key={index}>
									{imgSrc}
									<Text color='gray'>{info.description}</Text>
								</InfoWrapper>
							);
						})}
					</Flex>
				</Container>
			</Section>
		</div>
	);
};

export default Services;
