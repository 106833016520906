export const services = [
	{ name: "Montaż instalacji elektrycznych, strukturalnych (internetowych i telewizyjnych), alarmowych" },
	{ name: "Montaż instalacji monitoringu" },
	{ name: "Montaż instalacji Smart Home" },
	{ name: "Montaż osprzętu elektroinstalacyjnego (gniazda, wyłączniki itp.)" },
	{ name: "Montaż lamp i oświetlenia LED" },
	{ name: "Modernizacje i rozbudowy instalacji" },
	{ name: "Podłączanie urządzeń elektrycznych i sprzętów AGD" },
	{ name: "Zasilanie placu budowy" },
	{ name: "Wykonanie instalacji odgromowych i przeciwprzepięciowych" },
	{ name: "Pomiary instalacji elektrycznych" },
];

// export const realizationCategories = [
// 	{
// 		id: 1,
// 		title: "Rozdzielnie",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://i.imgur.com/ZXBtVw7.jpg",
// 	},
// 	{
// 		id: 2,
// 		title: "Uziemnienie",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://s7.postimg.cc/abavelo3v/1_3x.png",
// 	},
// 	{
// 		id: 3,
// 		title: "Klatka schodowa",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://i.imgur.com/ZXBtVw7.jpg",
// 	},
// 	{
// 		id: 4,
// 		title: "Nasza ekipa",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://s7.postimg.cc/hsk2th5tn/6_3x.png",
// 	},
// 	{
// 		id: 5,
// 		title: "Busik",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://i.imgur.com/ZXBtVw7.jpg",
// 	},
// 	{
// 		id: 6,
// 		title: "Programy w jakich działamy",
// 		description:
// 			"Rozdzielnice elektryczne są centralnym elementem instalacji elektrycznej budynku, zlokalizowanym zwykle w pobliżu złącza. Rozdzielnice elektryczne montuje się w obiektach wielorodzinnych lub większych jednorodzinnych w sytuacji, gdy z jednego złącza zasila się więcej niż jedną wewnętrzną linię zasilającą.",
// 		image: "https://s7.postimg.cc/abavelo3v/1_3x.png",
// 	},
// ];

export const aboutCompany = [
	{
		id: 2,
		imgSrc: "../../../assets/logo.png",
		description:
			"Śledząc bieżące trendy doradzamy klientom tak, aby instalacja w ich mieszkaniu, domu lub firmie była komfortowa w użytkowaniu i co najważniejsze, była trwała",
	},
	{
		id: 3,
		imgSrc: "../assets/idea.png",
		description:
			"Do realizacji zadań wybieramy materiały i narzędzia wiodących marek, co daje gwarancję szybkiej i rzetelnej realizacji powierzonych prac",
	},
	{
		id: 4,
		imgSrc: "../assets/idea.png",
		description: "Na każde zlecenie udzielamy gwarancji",
	},
	{
		id: 5,
		imgSrc: "../assets/idea.png",
		description: "Na bieżąco śledzimy przepisy i normy po to, aby nasza praca była wykonana zgodnie z ogólnymi zasadami bezpieczeństwa",
	},
];
